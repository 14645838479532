#comingSoonPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/carro-sv.appspot.com/o/assets%2FcomingSoon%2FauttoComingSoonBackground.png?alt=media&token=81434dc5-a01d-4496-b0c8-fcf798a55bd4');
  background-repeat: no-repeat;
  background-size: cover;
}

#comingSoonPage p {
  color: #FFFFFF
}

#comingSoonPageHeader {
  margin-bottom: 0px;
  line-height: 75%;
  font-size: 125px;
  font-weight: 700;
  font-style: italic;
}

#comingSoonPageSubheader {
  margin-bottom: 0px;
  font-size: 25px;
  font-weight: 400;
  line-height: 50%;
}

@media only screen and (max-width: 768px) {
  #comingSoonPage {
    background-position: 30%;
  }

  #comingSoonPageHeader {
    font-size: 100px;
  }
}

.App {
  min-height: 100vh !important;
  width: 100vw !important;
}

.App.shadowed header, .App.shadowed .page, .App.shadowed footer{
  opacity: 0.5;
}

.logo {
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
}

.loadingPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-shadow: rgba(33, 33, 33, 0.15) 0px 4px 15px;
}

header a {
  text-decoration: none;
  color: #444444;
  font-weight: 600;
  font-size: 16px;
}

.navbarItem.active {
  color: #20D17D;
}

header svg {
  width: 25px;
  height: 25px;
  color: #20D17D;
}

#headerLinksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#sidebar {
  background-color: #20D17D;
  position: fixed;
  right: 0px;
  height: 100vh;
  width: 75%;
  z-index: 1000
}

#sidebarContainer {
  padding: 1rem;
}

#sidebar svg {
  color: #FFFFFF;
  width: 35px;
  height: 35px;
  margin-bottom: 2rem;
}

#sidebar .navbarItem {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 0px;
  cursor: pointer;
  margin-bottom: 2rem;
  display: block;
  text-decoration: none;
}

#sidebar .navbarItem.active {
  color: #FFFFFF;
}

#sidebar .navbarBtn {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 0px;
  cursor: pointer;
  margin-bottom: 2rem;
  display: block;
}

#sidebar .primaryBtn {
  font-weight: 700 !important;
  border: 1px solid #FFFFFF;
  box-shadow:0px 0px 0px 1px #FFFFFF inset;
  color: #FFFFFF;
}

#sidebar img {
  width: 100px;
  height: 100px !important;
}

.page {
  min-height: 100vh;
  padding: 1rem;
}

.searchAndFiltersContainer {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.searchAndFiltersContainer div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.pageHeader {
  font-weight: 800;
  font-size: 32px;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.primaryBtn {
  border: 1px solid #20D17D;
  background-color: #20D17D;
  border-radius: 15px;
  color: #FFFFFF;
  outline: none;
  padding: 0.75rem 2rem;
  font-size: 16px;
  font-weight: 500 !important;
  cursor: pointer;
}

.primaryBtn:hover {
  border: 1px solid #20D17D;
  background-color: #20D17D;
}

.primaryBtn:active {
  border: 1px solid #20D17D !important;
  background-color: #20D17D !important;
}

.primaryBtn:disabled {
  border: 1px solid #20D17D00;
  background-color: #20D17D99;
}

.secondaryBtn {
  border: 1px solid #20D17D;
  box-shadow:0px 0px 0px 1px #20D17D inset;
  background-color: #FFFFFF;
  border-radius: 15px;
  color: #20D17D;
  outline: none;
  padding: 0.75rem 2rem;
  font-size: 16px;
  font-weight: 500 !important;
  cursor: pointer;
}

.secondaryBtn:hover {
  border: 1px solid #20D17D;
  background-color: #FFFFFF;
  color: #20D17D;
}

.secondaryBtn:active {
  border: 1px solid #20D17D !important;
  background-color: #FFFFFF !important;
}

.secondaryBtn:disabled {
  border: 1px solid #20D17D99;
  box-shadow:0px 0px 0px 1px #20D17D99 inset;
  background-color: #FFFFFF;
  color: #20D17D99;
}

button svg {
  margin-right: 0.5rem;
  width: 20px;
  height: 20px;
}

.searchAndFiltersContainer div button {
  width: 49%;
}

.noResultsFound {
  text-align: center;
}

footer {
  background-color: #444444;
  color: #FFFFFF;
  padding: 2rem 1rem;
}

footer .header {
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

footer div div {
  display: flex;
  flex-direction: column;
}

footer div {
  margin-bottom: 1.5rem;
}

footer a {
  text-decoration: none;
  color: #FFFFFF;
}

.inputAndBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.inputAndBtnContainer input {
  width: 70%;
  border-radius: 15px 0px 0px 15px;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 45px;
  border: 1px solid #d2d2d7;
  border-right: none;
  outline: none;
  font-size: 16px;
}

.inputAndBtnContainer button {
  width: 30%;
  border-radius: 0px 15px 15px 0px;
  padding: 0px;
  height: 45px;
}

footer p {
  margin: 0px;
}

footer hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

footer #footerLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

footer #footerSocials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;
}

footer #footerSocials .socialContainer {
  margin-bottom: 0px;
  background-color: #20D17D;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

footer #footerSocials .socialContainer svg {
  width: 20px;
  height: 20px;
}

.modal-content {
  padding: 1rem !important;
  border-radius: 15px !important;
  padding-bottom: 1.5rem !important;
}

.modal-content .closeModalButton {
  color: #20D17D;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modalHeader {
  font-weight: 700;
  font-size: 30px;
  color: #444444;
}

.tabBar {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.tabBar div {
  padding: 1rem;
  border-bottom: 1px solid #DDDDDD;
  cursor: pointer;
  color: #888888;
}

.tabBar div.active {
  border-bottom: 3px solid #20D17D;
}

.tabBar div.active p {
  color: #20D17D;
}

.tabBar div p {
  margin-bottom: 0px;
  width: max-content;
}

.tabContainer {
  padding: 1rem;
}

p.label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #444444;
}

input.mainInput, select.mainInput, textarea.mainInput {
  border: 1px solid #d2d2d7;
  outline: none;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 15px;
}

input.mainInput::placeholder, textarea.mainInput::placeholder {
  color: #999999;
}

input.mainInput:focus, textarea.mainInput:focus {
  border-color: #d2d2d7 !important;
  box-shadow: none !important;
}

div:where(.swal2-icon).swal2-warning, div:where(.swal2-icon).swal2-question {
  color: #20D17D !important;
  border-color: #20D17D !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  border-radius: 15px !important;
}

div:where(.swal2-container) div:where(.swal2-popup) button {
  border-radius: 15px !important;
  padding: 0.75rem 1.5rem;
  /* font-weight: 500;
  font-size: 16px; */
}

div:where(.swal2-icon).swal2-success {
  color: #20D17D !important;
  border-color: #20D17D !important;
}

.loadingModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.adCard {
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: #21212126 0px 4px 15px;
  margin-bottom: 0.5rem;
}

.adCardImage {
  width: 100%;
  background-color: #DDDDDD;
  border-radius: 15px;
  margin-bottom: 1rem;

}

.adCardPrice {
  font-weight: 700;
  font-size: 24px;
  margin: 0px
}

.adCardPrice svg {
  float: right;
  width: 30px;
  height: 30px;
  margin-top: -3px;
  color: #20D17D;
}

.adCardTitle {
  font-weight: 700;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.adCardKm {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.adCardNumOfOwners {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.adCardKm svg, .adCardNumOfOwners svg {
  margin-right: 0.5rem;
  margin-top: -5px;
}

.backBtn {
  color: #20D17D;
}

.backBtn svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

#adCTAsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#adCTAsContainer button {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

#adCTAsContainer button svg {
  margin-top: -7.5px;
}

.subheader {
  font-weight: 700;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.heroImage {
  width: 100%;
  border-radius: 15px;
}

#buyPage {
  padding-bottom: 2rem;
}

#buyPage button svg {
  margin-top: -2.5px;
}

#singleAdPage button svg {
  margin-bottom: -5px;
}

#singleAdPageSimilarVehiclesContainer {
  margin-bottom: 1rem;
}

#singleAdPageSimilarVehiclesContainer .adCard {
  width: 300px;
  margin-right: 0.5rem;
  margin-bottom: 0px;
  box-shadow: none;
  border: 1px solid #DDDDDD;
}

.react-horizontal-scrolling-menu--wrapper ::-webkit-scrollbar {
  display: none;
}

.vehicleDetailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.vehicleDetailsRow p {
  margin: 0px;
}

.vehicleDetailsRow p svg {
  margin-right: 0.5rem;
  margin-top: -2.5px;
  max-width: 75%;
}

.vehicleDetailsRowValue {
  font-weight: bold;
  max-width: 25%;
}

#moreDetailsModal .vehicleDetailsRow {
  margin-bottom: 2rem;
}

.btnCard {
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: #21212126 0px 4px 15px;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btnCard svg {
  width: 25px;
  height: 25px;
  color: #20D17D;
}

.btnCard.active {
  box-shadow:0px 0px 0px 2px #20D17D inset;
}

.quantityToggler {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.quantityToggler p {
  margin-bottom: 0px;
  margin-left: 2rem;
  margin-right: 2rem;
}

.quantityToggler svg {
  width: 35px;
  height: 35px;
}

.range-slider {
  margin-top: 1rem;
}

.sliderValues {
  margin-top: 1rem;
  margin-bottom: 0px;
  text-align: center;
}

.range-slider__range {
  background: #20D17D !important;
  transition: height 0.3s;
}

.range-slider__thumb {
  background: #20D17D !important;
  transition: transform 0.3s;
}

.emptyMapText {
  text-align: center;
}

#logInToSellYourVehicleContainer {
  text-align: center;
  padding-top: 50%;
}

/* CONTACT US PAGE */

/* EDIT SINGLE AD PAGE */

#editAdCardImageContainer {
  position: relative;
  display: block;
  width: 80vw;
}

#editAdCardImageContainer .svgContainer {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 30px;
  height: 30px;
  background-color: #20D17D;
  border-radius: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#editAdCardImageContainer .svgContainer svg {
  width: 25px;
  height: 25px;
  color: #FFFFFF;
}

#addVehicleImageBtn {
  height: 100%;
  width: 90vw;
  border-radius: 15px;
  background-color: #20D17D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#addVehicleImageBtn svg {
  color: #FFFFFF;
  width: 50px;
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

#addVehicleImageBtn p {
  color: #FFFFFF;
  font-weight: 500;
  margin-bottom: 3rem;
}

/*  */

.btnCard {
  display: block;
}

#desktopComingSoonScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  background-color: #20D17D;
}

#desktopComingSoonScreen p {
  color: #FFFFFF;
  margin: 0px;
}

#desktopComingSoonHeader {
  font-size: 50px;
  font-weight: 700;
  font-style: italic;
}

#desktopComingSoonSubheader {
  font-size: 25px;
  font-weight: 400;
  line-height: 50%;
}

@media only screen and (max-width: 768px) {
  #desktopComingSoonScreen {
    display: none;
  }
}